import {store} from "@/store/index.ts";

export const useDataStore = defineStore({
    id: 'data',
    state: () => ({
        data: {},
        channelCode: 'avlove_001'
    }),
    getters: {
        getData(): any {
            return this.data;
        },
        getChannelCode(): any {
            return this.channelCode
        }
    },
    actions: {
        setData(data: any) {
            this.data = data
        },
        setChannelCode(data: any) {
            this.channelCode = data
        }
    },
    persist: true,
})

export function useDataStoreWithOut() {
    return useDataStore(store);
}